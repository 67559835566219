<template>
  <vue-final-modal class="modal-languages">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'title') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('languages')" />
      </div>
    </div>

    <div class="vfm__body">
      <div class="modal-languages__list">
        <button-base
          v-for="locale in locales"
          :key="locale.code"
          :type="currentLocale?.code.toLowerCase() === locale.code.toLowerCase() ? 'primary' : 'secondary-3'"
          :class="{ 'is-selected': currentLocale?.code.toLowerCase() === locale.code.toLowerCase() }"
          @click.prevent="changeLanguage(locale)"
        >
          <span class="modal-languages__icon">
            <atomic-image class="modal-languages__img" :src="`/img/languages/${locale.code.toLowerCase()}.svg`" />
          </span>

          <span class="modal-languages__name">{{ locale.nativeName || locale.name }}</span>
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent, ILocale } from '~/types';
  import { storeToRefs } from 'pinia';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['categories']>;
    defaultLocaleData: Maybe<CIModalsContent['categories']>;
  }>();

  const { closeModal } = useModalStore();
  const { getContent } = useProjectMethods();

  const route = useRoute();
  const globalStore = useGlobalStore();
  const { locales, currentLocale } = storeToRefs(globalStore);
  const isProcess = ref<boolean>(false);
  const cookieLanguage = useCookie('user-language', { maxAge: 60 * 60 * 24 * 365 * 10 });
  const { changeProfileData } = useCoreProfileApi();
  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);

  const changeLanguage = async (locale: ILocale): Promise<void> => {
    if (currentLocale.value?.code === locale.code || isProcess.value) return;
    isProcess.value = true;
    cookieLanguage.value = locale.code.toLowerCase();

    if (isLoggedIn.value) {
      await changeProfileData({ locale: locale.code });
    }

    await closeModal('languages');
    window.location.href = linkToLocale(locale);
  };

  const linkToLocale = (locale: ILocale): string => {
    const routerLocale: any = route.params.locale;
    const pathRegexp = new RegExp(`^/${routerLocale}/|^/${routerLocale}$`);
    const pathWithoutLocale = routerLocale ? route.fullPath.replace(pathRegexp, '/') : route.fullPath;

    if (locale.isDefault) return pathWithoutLocale;
    return `/${locale.code.toLowerCase()}${pathWithoutLocale === '/' ? '' : pathWithoutLocale}`;
  };
</script>

<style src="~/assets/styles/components/modal/languages.scss" lang="scss" />
